'use strict';

if (typeof window === 'object') {
  window.Notifier = Notifier;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = Notifier;
}

/**
 * Wrapper around noty() for some standard notifications
 *
 * @class
 * @public
 * @constructor
 * @param {Function} notifyFunction (noty)
 * @returns {undefined}
 */
function Notifier(notifyFunction) {
  this._notify = notifyFunction;
}

/**
 * Show a notification of type
 *
 * @public
 * @param {string} type One of success, error, warning, information, notification
 * @param {string} message
 * @returns {undefined}
 */
Notifier.prototype.show = function (type, message) {
  if (['success', 'error', 'warning', 'information', 'notification'].indexOf(type) === -1) {
    type = 'error';
  }
  this._notify(this._config(type, message));
};

/**
 * Show an error notification
 *
 * @public
 * @param {string} message
 * @returns {undefined}
 */
Notifier.prototype.showError = function (message) {
  this.show('error', message);
};

/**
 * Show a success notification
 *
 * @public
 * @param {string} message
 * @returns {undefined}
 */
Notifier.prototype.showSuccess = function (message) {
  this.show('success', message);
};

/**
 * Show a warning notification
 *
 * @public
 * @param {string} message
 * @returns {undefined}
 */
Notifier.prototype.showWarning = function (message) {
  this.show('warning', message);
};

/**
 * Show a sticky warning notification
 *
 * @public
 * @param message
 */
Notifier.prototype.showStickyWarning = function(message) {
  let config = this._config('warning', message);
  config.timeout = false;
  this._notify(config);
};

/**
 * Close all notifications
 *
 * @public
 * @returns {undefined}
 */
Notifier.prototype.closeAll = function () {
  $.noty.closeAll();
};

/**
 * Return the config
 *
 * @private
 * @param {string} type
 * @param {string} message
 * @returns {Object} The config object
 */
Notifier.prototype._config = function (type, message) {
  return {
    layout: 'topCenter',
    theme: 'relax',
    type,
    timeout: 5000,
    text: message,
    animation: {
      open: {opacity: 'toggle'},
      close: {opacity: 'toggle'}
    }
  };
};
