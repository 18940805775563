'use strict';

if (typeof window === 'object') {
  window.ErrorReporter = ErrorReporter;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = ErrorReporter;
}

/**
 * Report a JavaScript error
 *
 * @class
 * @public
 * @constructor
 * @param {Object|undefined} reporter (Sentry)
 * @returns {undefined}
 */
function ErrorReporter(reporter) {
  this._reporter = reporter;
}

/**
 * Report an ajax error
 *
 * @public
 * @param {string} url
 * @param {Object} ajaxOptions
 * @param {Object} jqXhr
 * @returns {undefined}
 */
ErrorReporter.prototype.reportAjaxError = function (url, ajaxOptions, jqXhr) {
  // Don't report if we don't have a reporter
  if (!this._hasReporter()) {
    return;
  }

  this.reportMessage(jqXhr.statusText, {
    extra: {
      type: ajaxOptions.type,
      url: url,
      data: ajaxOptions.data,
      status: jqXhr.status,
      error: jqXhr.statusText,
      response: typeof jqXhr.responseText !== 'undefined' ? jqXhr.responseText.substring(0, 100) : ''
    }
  });
};

/**
 * Report an exception
 *
 * @public
 * @param {Exception} exception
 * @returns {undefined}
 */
ErrorReporter.prototype.reportException = function (exception) {
  // Don't report if we don't have a reporter
  if (!this._hasReporter()) {
    return;
  }
  this._reporter.captureException(exception);
};

/**
 * Report a message
 *
 * @public
 * @param {string} message
 * @param {Object} data
 * @returns {undefined}
 */
ErrorReporter.prototype.reportMessage = function (message, data) {
  // Don't report if we don't have a reporter
  if (!this._hasReporter()) {
    return;
  }
  this._reporter.withScope(scope => {
    for (const key in data) {
      scope.setExtra(key, data[key]);
    }
    this._reporter.captureMessage(message);
  });
  // this._reporter.captureMessage(message, data);
};

/**
 * Return if we can report
 *
 * @private
 * @returns {boolean}
 */
ErrorReporter.prototype._hasReporter = function () {
  return typeof this._reporter !== 'undefined';
};
