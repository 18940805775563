(function() {
  /* initiate */
  if (window.MedimoSessionStorage.isSupported()) {
    // initiate objects
    window.medimo.SessionStorage = new window.MedimoSessionStorage('breadcrumb');
    window.medimo.RememberSearch = new window.MedimoSessionStorage('remembersearch');
    window.medimo.Breadcrumb = new window.Breadcrumbs();

    // breadcrumb storage
    window.medimo.Breadcrumb.visitUrl(window.location.pathname+window.location.search);
  }
})();
