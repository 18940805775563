window.jQuery.fn.extend({
  hideZero: function () {
    return this.each(function () {
      window.$(this).on('focus', function () {
        /* clear the field on focus*/
        if (parseFloat(this.value.replace(",", ".")) === 0) {
          this.value = '';
        }
      }).on('blur', function () {
        /* set a small timeout to prevent shifting from blank to 0 to a value set outside with js. */
        var element = this;
        setTimeout(function () {
          element.value = element.value.replace(",", ".");
          if (element.value.length <= 0) {
            element.value = '0';
          }
        }, 200);
      });
    });
  }
});

window.$(function() {
  window.$('.hideZero').hideZero();
});
