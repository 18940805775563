'use strict';

if (typeof window === 'object') {
  window.MedimoSessionStorage = MedimoSessionStorage;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = MedimoSessionStorage;
}

function MedimoSessionStorage(storageKey) {
  this._storageKey = storageKey;
}

/**
 * Return if session storage is supported
 *
 * @static
 * @public
 * @returns {boolean}
 */
MedimoSessionStorage.isSupported = function () {
  // Test based on Modernizr test for sessionStorage
  var test = 'test';
  try {
    window.sessionStorage.setItem(test, test);
    if (window.sessionStorage.getItem(test) !== 'test') {
      return false;
    }
    window.sessionStorage.removeItem(test);
    return true;
  } catch (error) {
    return false;
  }
};

MedimoSessionStorage.prototype.getValue = function() {
  return JSON.parse(window.sessionStorage.getItem(this._storageKey));
};

MedimoSessionStorage.prototype.setValue = function(val) {
  try {
    window.sessionStorage.setItem(this._storageKey, JSON.stringify(val));
  } catch(err) {
    // possibly storage full
  }
};

MedimoSessionStorage.prototype.addValue = function(val) {
  var value = this.getValue(this._storageKey);
  if(value === null) {
    value = [];
  }
  value.push(val);
  this.setValue(value);
};

MedimoSessionStorage.prototype.setItemValueForKey = function(key, value) {
  var items = this.getValue();
  if(items !== null) {
    var item = items.pop();
    item[key] = value;
    items.push(item);
    this.setValue(items);
  }
};

MedimoSessionStorage.prototype.clearValue = function() {
  window.sessionStorage.removeItem(this._storageKey);
};