'use strict';

// Set Sentry to undefined if it is undefined.
// This creates the Sentry variable, so we can pass it as a parameter to ErrorReporter()
if (typeof Sentry === 'undefined') {
  window.Sentry = undefined;
}

// Add notifier, errorReporter, connectionManager and ajax to window
window.notifier = new window.Notifier(window.noty);
window.errorReporter = new window.ErrorReporter(window.Sentry);
window.connectionManager = new window.ConnectionManager(window.notifier, window.errorReporter);
window.ajax = new window.Ajax(window.connectionManager);

window.$(function() {
  // bind href response to modal
  $("a.data-modal").click(function(ev) {
    ev.preventDefault();

    const url = $(this).attr("href");
    window.ajax.get(url, {
      success: function (data) {
        $(data).modal();
      }
    });
  });

  $("a.click-and-confirm").on("click", function(ev) {
    ev.preventDefault();
    const anchor = $(this);
    medimo.ModalActions.callbackModal(anchor.text(), anchor.data("question"), [
      {
        label: "Doorgaan",
        attributes: {
          href: "#",
          class: "half"
        },
        callback: function() {
          window.location = anchor.attr("href");
        }
      },
      {
        label: "Sluiten",
        attributes: {
          href: "#",
          class: "close secondary half"
        },
        callback: function() {}
      }
    ]);
  });
});

/**
 *
 * GLOBAL MEDIMO HELPERS
 *
 */

window.saveFile = function (base64Data, mimeType, fileName)
{
  if (window.medimo.fileSaver === undefined) {
    window.medimo.fileSaver = new FileSaver;
  }
  window.medimo.fileSaver.saveAs(base64Data, mimeType, fileName);
};

function setValue(where, what) {
  $(where).val(what);

  return false;
}

window.confirmPopup = function (title, message, confirmLabel, confirmIcon, callback) {
  medimo.ModalActions.callbackModal(title, message, [{
    label: confirmLabel,
    icon: confirmIcon,
    attributes: {
      class: '_button close'
    },
    callback
  }, {
    label: 'Annuleren',
    attributes: {
      class: '_button close secondary'
    },
    callback: function() {}
  }]);
};

window.auto_resize_formajaxprocessor_modal = function () {
  const wrapper = $("#modal.medimomodal-wrapper");
  const modal = wrapper.find(".medimomodal");

  // get geometric
  const window_height = $(window).height();
  const modal_height = modal.height();

  let offset = 0; // Lijkt een bug met jQuery in Vue3 waarbij de modal_height altijd 0 is, in dat geval kan het zijn dat het onderste deel weg valt, dus dan liever de default offset van 20px.
  if (modal_height !== 0) {
    offset = (window_height - modal_height) / 5; // Niet compleet centered, maar een marge bovenin, net als v3
  }

  if (offset < 0) {
    offset = 20;
    modal.css("max-height", window_height - 40 + "px");
    modal.find(".medimomodal-content").css({
      overflowY: "scroll",
      height: window_height - 150 + "px",
      maxHeight: window_height - 150 + "px"
    });
  } else {
    modal.find(".medimomodal-content").css({
      overflowY: "auto",
      maxHeight: window_height - 250 + "px"
    });
  }

  modal.css("margin-top", offset + "px");
};

window.auto_resize_active_medimo_modal = function () {
  window.auto_resize_formajaxprocessor_modal(); // allow renaming of this function in the future
  window.addEventListener('resize', window.auto_resize_formajaxprocessor_modal); // resize on window resize
};

/**
 * Guard that the $dateTimeElement has a date within range when the input is changed by the user.
 *
 * We check that the date is within the min and max date range, as dateTimePicker does not check this for manual
 * inputted data. Furthermore dateTimePicker allows a manual inputted date with a year smaller than the year 1000. Which
 * means that years like 209, which is obviously a typo for 2019, are ok according to dateTimePicker. The problem is
 * that Carbon::parse() breaks on this.
 * So we have to guard this ourselves...
 *
 * @param {jQuery} $dateTimeElement
 * @param {bool} includesDate
 * @param {bool} includesTime
 * @param {Date} minDate
 * @param {Date} maxDate
 * @returns void
 */
window.sanitizeDateTime = function ($dateTimeElement, includesDate = true, includesTime = false, minDate = null, maxDate = null) {
  if (minDate === null) {
    minDate = new Date(1900, 0, 1); // Default minimum date.
  }

  if (maxDate === null) {
    maxDate = new Date(2099, 11, 31); // Default maximum date.
  }

  const dateSanitizer = new DateSanitizer();

  // Store default color.
  $dateTimeElement.data('defaultColor', $dateTimeElement.css('color'));

  // Reset default color on focus.
  $dateTimeElement.on('focus', function () {
    const defaultColor = $dateTimeElement.data('defaultColor');
    if ($dateTimeElement.css('color') !== defaultColor) {
      $dateTimeElement.css('color', defaultColor);
    }
  });

  // We use blur as this should be executed before dateTimePicker picks up the value to do its own sanitizing work.
  $dateTimeElement.on('blur', function () {
    let sanitizedValue = null;
    if (includesDate && includesTime) {
      sanitizedValue = dateSanitizer.sanitizeDateTime(this.value);
    } else if (includesDate) {
      sanitizedValue = dateSanitizer.sanitizeDate(this.value);
    } else {
      sanitizedValue = dateSanitizer.sanitizeTime(this.value);
    }

    if (sanitizedValue === null) {
      // We could not sanitize the value.
      setErrorColor($dateTimeElement);
    } else {
      // Set the sanitized value;
      this.value = sanitizedValue;

      // Check if the date is within range.
      const sanitizedDate = new Date(sanitizedValue);
      if (sanitizedDate < minDate || sanitizedDate > maxDate) {
        setErrorColor($dateTimeElement);
      }
    }
  });

  if ($dateTimeElement.data('xdsoft_datetimepicker')) {
    $dateTimeElement.datetimepicker({ onclose: (ct) => {
      // We have to add the onclose function here, otherwise datetimepicker will act before the on blur event we just
      // create above.
    }});
  }

  function setErrorColor($dateTimeElement) {
    $dateTimeElement.css('color', '#CD0A0A'); // @mmred
  }
};

/**
 * Lock the scroll bar.
 *
 * @returns void
 */
window.scrollLock = function () {
  const $body = window.$('body');
  if ($body.css('position') === 'fixed') {
    return; // Scroll position is already locked.
  }

  $body.css({
    position: 'fixed',
    width: '100%',
    top: '-' + window.$(document).scrollTop() + 'px', // Keep the current scroll position.
  });
};

/**
 * Make the scroll bar scrollable again.
 *
 * @returns void
 */
window.scrollFree = function () {
  const $body = $('body');
  if ($body.css('position') !== 'fixed') {
    return; // Scroll position is not locked.
  }

  const scrollPosition = parseInt($body.css('top').replace('px', ''), 10) * -1; // Set radix to be sure we use base 10.
  $body.removeAttr('style');
  window.$(document).scrollTop(scrollPosition); // Keep the current scroll position.
};

window.medimo.getCookie = function(cName) {
  const name = cName + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

window.medimo.toggleContrast = function() {
  if (window.medimo.getCookie('high-contrast') === 'true') {
    // Remove cookie and class
    document.cookie = 'high-contrast=false;path=/';
    document.getElementsByTagName('body')[0].classList.remove('high-contrast');
    return;
  }

  // Add cookie and class
  document.cookie = 'high-contrast=true;path=/';
  document.getElementsByTagName('body')[0].classList.add('high-contrast');
};
