'use strict';

if (typeof window === 'object') {
  window.MedimoLocalStorage = MedimoLocalStorage;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = MedimoLocalStorage;
}

/**
 * MedimoLocalStorage class
 *
 * @param {string} key
 * @returns {undefined}
 * @constructor
 */
function MedimoLocalStorage(key) {
  this._key = key;
}

/**
 * Return if local storage is supported
 *
 * @static
 * @public
 * @returns {boolean}
 */
MedimoLocalStorage.isSupported = function () {
  // Test based on Modernizr test for localStorage
  const test = 'test';
  try {
    window.localStorage.setItem(test, test);
    if (window.localStorage.getItem(test) !== 'test') {
      return false;
    }
    window.localStorage.removeItem(test);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Return the local storage value
 *
 * @public
 * @returns {*}
 */
MedimoLocalStorage.prototype.get = function () {
  try {
    const item = window.localStorage.getItem(this._key);
    if (item === null || item === '') { // item === '' for IE
      return null;
    }
    return JSON.parse(item);
  } catch (error) {
    return null;
  }
};

/**
 * Set the local storage value
 *
 * @public
 * @param {*} value
 * @returns {boolean}
 */
MedimoLocalStorage.prototype.set = function (value) {
  try {
    window.localStorage.setItem(this._key, JSON.stringify(value));
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Remove the local storage value
 *
 * @public
 * @returns {boolean}
 */
MedimoLocalStorage.prototype.remove = function () {
  try {
    window.localStorage.removeItem(this._key);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Return the key
 *
 * @public
 * @returns {string}
 */
MedimoLocalStorage.prototype.key = function () {
  return this._key;
};
