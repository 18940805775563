'use strict';

/*CAUTION: This script will disable XSS filtering on items in the list! */
window.select2MultiWordMatchAjax = {
    minimumInputLength: 3,
    language: 'nl',
	formatResult: function(result, container, query, escapeMarkup) {
	    var terms = query.term.split(" ");
	    var text = result.text;
	    for(var i = 0; i < terms.length; i++) {
	        var markup=[];
	        Select2.util.markMatch(text, terms[i], markup, escapeMarkup);
	        text = markup.join("");
	    }
	    return text;
	},
	escapeMarkup: function(m) { return m; }
};

window.legacyOldMatcher = function(matcher) {
	function wrappedMatcher (params, data) {
		var match = $.extend(true, {}, data);
		if (params.term == null || $.trim(params.term) === '') {
			return match;
		}

		if (data.children) {
			for (var c = data.children.length - 1; c >= 0; c--) {
				var child = data.children[c];

				// Check if the child object matches
				// The old matcher returned a boolean true or false
				var doesMatch = matcher(params.term, child.text, child);

				// If the child didn't match, pop it off
				if (!doesMatch) {
					match.children.splice(c, 1);
				}
			}

			if (match.children.length > 0) {
				return match;
			}
		}

		if (matcher(params.term, data.text, data)) {
			return match;
		}

		return null;
	}

	return wrappedMatcher;
};

window.mmSelect2Matcher = function(params, data) {
	var searchTerm = params;
	if($.trim(searchTerm) === '') return data;
	var allParams = searchTerm.split(' ');
	var matched = true;
	for (var i = 0; i < allParams.length; i++ ) {
		if (data.toLowerCase().indexOf(allParams[i].toLowerCase()) > -1) continue;
		matched = false;
		break;
	}
	return matched;
};

window.select2MultiWordMatchNonAjax = {
	language: 'nl',
	minimumResultsForSearch: 20,
	minimumInputLength: 0,
	matcher: legacyOldMatcher(window.mmSelect2Matcher)
};

window.select2MultiWordMatch = $.extend({}, window.select2MultiWordMatchNonAjax, {});
