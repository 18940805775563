'use strict';

if (typeof window === 'object') {
  window.Switch = Switch;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = Switch;
}

/**
 * Class Switch
 *
 * Creates a multi switch on hidden input fields
 *
 * @class
 * @public
 * @constructor
 * @param {
 *  inputElementsSelector: string, jQuery selector for the input fields
 *  switchHtmlTemplate: string, html template for the switch element
 *  states: [{
 *    value: string|int,
 *    title: string,
 *    html: string, html to insert in switch element
 *    class: string class name to add to the switch element
 * }]} options
 * @returns undefined
 */
function Switch(options) {
  if (!options.hasOwnProperty('inputElementsSelector')) {
    throw 'options.inputElementsSelector should be defined';
  } else if (!options.hasOwnProperty('switchHtmlTemplate')) {
    throw 'options.switchHtmlTemplate should be defined';
  } else if (!options.hasOwnProperty('states')) {
    throw 'options.states should be defined';
  }

  this._SWITCH_CLASS_NAME = 'm-switch';

  this._options = options;
  this._switchElements = [];
  this._baseClass = '';

  var Switch = this;
  var states = this._options.states;

  // Create switch elements for all input elements that jQuery finds with options.inputElementsSelector
  $(options.inputElementsSelector).each(function () {
    var $inputElement = $(this);
    var $switchElement = $(options.switchHtmlTemplate);

    // Create base class
    if (Switch._baseClass === '') {
      Switch._baseClass = Switch._SWITCH_CLASS_NAME + ' ' + $switchElement.attr('class');
    }

    Switch._switchElements[$inputElement.attr('name')] = {
      $switch: $switchElement,
      $input: $inputElement
    };

    // Copy $inputElement id, name and disabled attributes to $switchElement
    if (typeof $inputElement.attr('id') !== 'undefined') {
      $switchElement.attr('id', $inputElement.attr('id'));
    }
    if (typeof $inputElement.attr('name') !== 'undefined') {
      $switchElement.attr('data-name', $inputElement.attr('name'));
    }
    if (typeof $inputElement.attr('disabled') !== 'undefined') {
      $switchElement.attr('disabled', $inputElement.attr('disabled'));
    }

    // Grab default state from input field
    var defaultState;
    for (var key in states) {
      var inputValue = parseInt($inputElement.val());
      if (states.hasOwnProperty(key)) {
        if (states[key].value === inputValue) {
          defaultState = states[key];
          break;
        }
      }
    }

    // No default state not found? Set to first state
    if (typeof defaultState === 'undefined') {
      defaultState = states[0];
    }

    Switch._setSwitchState($switchElement, defaultState);

    $switchElement.on('click', function (event) {
      Switch.toggle($switchElement);
      event.preventDefault();
      event.stopPropagation();
    });

    // Add $switchElement to DOM
    $inputElement.parent().append($switchElement);
  });
}

/**
 * Toggle the $switchElement
 *
 * @public
 * @param {jQuery} $switchElement
 * @returns undefined
 */
Switch.prototype.toggle = function ($switchElement) {
  if ($switchElement.attr('disabled') === 'disabled') {
    return;
  }

  var states = this._options.states;
  var nextKey = 0;
  var lastKey = states.length - 1;
  for (var key = 0; key < lastKey; key ++) {
    if ($switchElement.hasClass(states[key].class)) {
      nextKey = key + 1;
      if (nextKey > lastKey) {
        nextKey = 0;
      }
      break;
    }
  }

  this._setSwitchState($switchElement, states[nextKey]);
};

/**
 * Set the $switchElement state
 *
 * @private
 * @param {jQuery} $switchElement
 * @param {Object} state
 * @returns undefined
 */
Switch.prototype._setSwitchState = function ($switchElement, state) {
  if (!state.hasOwnProperty('value')) {
    throw 'state.value should be defined';
  } else if (!state.hasOwnProperty('title')) {
    throw 'state.title should be defined';
  } else if (!state.hasOwnProperty('html')) {
    throw 'state.html should be defined';
  } else if (!state.hasOwnProperty('class')) {
    throw 'state.class should be defined';
  }

  $switchElement.val(state.value);
  $switchElement.attr('title', state.title);
  $switchElement.html(state.html);
  $switchElement.prop('class', this._baseClass + ' ' + state.class);
  this._switchElements[$switchElement.data('name')].$input.val(state.value).trigger('change');
};
