'use strict';

window.toggleSidebar = function(
  contentId,
  buttonId,
  hasContent,
  onlyOpen,
  onlyClose,
  noSlide
) {

  //Only toggle on actions and chats,
  //other buttons are removed (medicationguard and statusoverview)
  if(buttonId !== 'buttonActionPanel' && buttonId !== 'buttonChatPanel') {
    return;
  }

  var changeHasContent = false;
  if (typeof noSlide === "undefined") {
    noSlide = false;
  }

  if (typeof hasContent !== "undefined") {
    changeHasContent = true;
  }

  if (typeof onlyOpen == "undefined") {
    onlyOpen = false;
  }

  if (typeof onlyClose == "undefined") {
    onlyClose = false;
  }

  //Toggle hasContent class
  if (changeHasContent && !hasContent) {
    $("#" + buttonId).removeClass("hasContent");
  } else if (changeHasContent && hasContent) {
    $("#" + buttonId).addClass("hasContent");
  }

  //check if it was shown
  var contentIsVisible = $("#" + contentId).css("display") != "none";

  var sidebarIsVisible = $(".sidebar").hasClass("unfolded");

  if (sidebarIsVisible && onlyOpen) {
    return;
  } else if (!sidebarIsVisible && onlyClose) {
    return;
  }

  if (contentIsVisible) {
    //we have to hide the content
    //and collapse the sidebar
    if (!noSlide) {
      $("#" + contentId).hide("slide", { direction: "right" }, 200);
      $(".sidebar").toggle("slide", { direction: "right" }, 200);
      $(".sidebar").addClass("collapsed");
      $(".sidebar").removeClass("unfolded");
      $(".verticalButton").removeClass("highlighted");
      $("#leftFrame").toggleClass("squeezed");
    }
  } else {
    if (sidebarIsVisible) {
      //another pane was visible, hide that one before showing the new one
      $(".sidebar").children().hide();
      $(".verticalButton").removeClass("highlighted");
      //$('#' + contentId).show('slide', {direction: 'right'}, 200);
      $("#" + contentId).show();
      $("#" + buttonId).addClass("highlighted");
    } else {
      if (!noSlide) {
        $("#" + contentId).show();
        $(".sidebar").toggle("slide", { direction: "right" }, 200);
        $(".sidebar").addClass("unfolded");
        $(".sidebar").removeClass("collapsed");
        $("#" + buttonId).addClass("highlighted");
        $("#leftFrame").toggleClass("squeezed");
      }
    }
  }
};

var closeSidebar = function(contentId, buttonId, hasContent) {
  var noSlide = false;
  if (window.sideBarToggledByUser) noSlide = true;
  toggleSidebar(contentId, buttonId, hasContent, false, true, noSlide);
};

window.openSidebar = function(contentId, buttonId, hasContent) {
  var noSlide = false;
  if (window.sideBarToggledByUser) noSlide = true;

  toggleSidebar(contentId, buttonId, hasContent, true, false, noSlide);
};

var setButtonCounter = function(buttonId, counter) {
  if (counter == 0) {
    $("#counter" + buttonId).text(counter);
    $("#counter" + buttonId).hide();
  } else {
    $("#counter" + buttonId).text(counter);
    $("#counter" + buttonId).show();
  }
};

var buttonCounterPlusOne = function(buttonId) {
  var currentVal = parseInt($("#counter" + buttonId).text());
  var newVal = currentVal + 1;

  if (newVal == 0) {
    $("#counter" + buttonId).text(newVal);
    $("#counter" + buttonId).hide();
  } else {
    $("#counter" + buttonId).text(newVal);
    $("#counter" + buttonId).show();
  }
};

var buttonCounterMinusOne = function(buttonId) {
  var currentVal = $("#counter" + buttonId).text();
  var newVal = currentVal - 1;

  if (newVal == 0) {
    $("#counter" + buttonId).text(newVal);
    $("#counter" + buttonId).hide();
  } else {
    $("#counter" + buttonId).text(newVal);
    $("#counter" + buttonId).show();
  }
};

var highlightSidebar = function(buttonId) {
  $("#" + buttonId).addClass("highlighted");
};

var dimSidebar = function(buttonId) {
  $("#" + buttonId).removeClass("highlighted");
};

var showLoader = function(buttonId) {
  if(buttonId === 'buttonMedGuard') {
    $("#medicationGuardFrame .medimo-icon-is-spinning").show();
  }
  $("#" + buttonId + " > .loader").show();
};

var hideLoader = function(buttonId) {
  if(buttonId === 'buttonMedGuard') {
    $("#medicationGuardFrame .medimo-icon-is-spinning").hide();
  }
  $("#" + buttonId + " > .loader").hide();
};

window.updateMedicationGuardHandle = false;
window.lastUpdateMedicationGuardPromiseIndex = false;
window.initiateMedicationGuardHandle = false;
window.initiateMedicationGuardPromiseIndex = false;

window.addMedicationGuardFormUpdateListeners = function() {
  var bindings = [
      'input[name^="time_amount"]',
      'input[name^="nr"]',
      'input[name="infusionAmount"]',
      'select[name="dosageInterval"]',
      'select[name="dosingUnits"]',
      '#radio_usageType_C',
      '#radio_usageType_T',
      '#radio_usageType_Z',
      '#startdatetime',
      '#stopdatetime',
      '#mutationdatetime',
      '#amount'
    ];

  $("#formulier").on("change", bindings.join(','), updateMedicationGuardInfo);
  $('select[name="administrationRoute"]').on(
    "select2:select",
    updateMedicationGuardInfo
  );
    $('select[id="episodesMultiSelect"]').on(
        "select2:select",
        updateMedicationGuardInfo
    );
    $('select[id="episodesMultiSelect"]').on(
        "select2:unselect",
        updateMedicationGuardInfo
    );
};

window.updateMedicationGuardInfo = function(event) {
  var $form = $("#formulier");
  var $sidebar = $("#sidebarMultiButton");
  var processStep = $sidebar.data("processStep");
  var patientId = $sidebar.data("patientId");
  var drugId = $sidebar.data("drugId");
  var statusId = $sidebar.data("statusId");

  let postUrl = "/status/updatemedicationguarddosagesignals/" + processStep + "/" + patientId + "/" + drugId;
  if (typeof statusId !== "undefined" && statusId !== '') {
    postUrl += "/" + statusId;
  }

  // Give dateTimePicker some time to finish up before we do the request.
  if ($(this).data('xdsoft_datetimepicker')) {
    setTimeout(doUpdateRequest, 1);
  } else {
    doUpdateRequest();
  }

  function doUpdateRequest() {
    const initDeferred = $form.data("initiateMedGuardDeferred");
    if (typeof initDeferred === "undefined" || (typeof initDeferred.state == "function" && initDeferred.state() != "pending")) {
      sendUpdateRequest();
    } else {
      $.when(initDeferred).then(sendUpdateRequest);
    }
  }

  function sendUpdateRequest() {
    showLoader("buttonMedGuard");
    const formData = $form.serialize();

    if (typeof $form.data("deferredSubmit") === "undefined") {
      $form.data("deferredSubmit", []);
      window.updateMedicationGuardHandle = false;
    }

    if (window.updateMedicationGuardHandle === undefined) {
      window.updateMedicationGuardHandle = false;
    }

    if (window.updateMedicationGuardHandle !== false) {
      var promise = $form.data("deferredSubmit")[lastUpdateMedicationGuardPromiseIndex];
      if (typeof promise == "object" && typeof promise.resolve == "function") {
        promise.resolve();
      }

      window.updateMedicationGuardHandle.abort();
      window.updateMedicationGuardHandle = false;
    }

    var updateMedicationGuardDeferred = $.Deferred();
    lastUpdateMedicationGuardPromiseIndex = $form.data("deferredSubmit").push(updateMedicationGuardDeferred) - 1;

    window.updateMedicationGuardHandle = ajax.post(postUrl, {
      data: formData,
      success: function (result) {
        handleUpdateMedicationGuardResponse(result);
        updateMedicationGuardDeferred.resolve();
      }
    });
  }
};

var handleUpdateMedicationGuardResponse = function(result) {
  processAjaxUpdate(result);

  var numberOfSignals = $("#medicationGuardSignals").children().length;

  if (numberOfSignals > 0) {
    openSidebar("contentMedGuard", "buttonMedGuard", true);
    $("#noMedicationGuardSignalsDiv").hide();

    $("#medicationGuardSignals .panel-default")
      .sort(function(a, b) {
        if ($(a).data("order") == $(b).data("order")) {
          if( $(a).text() > $(b).text() ) {
            return 1;
          }
          else {
            return -1;
          }
        }
        else {
          return $(a).data("order") - $(b).data("order");
        }
      })
      .appendTo("#medicationGuardSignals");
  } else {
    closeSidebar("contentMedGuard", "buttonMedGuard", false);
    $("#noMedicationGuardSignalsDiv").show();
  }

  setButtonCounter("buttonMedGuard", numberOfSignals);

  autosize($(".autosizeMe"));

  hideLoader("buttonMedGuard");
};

window.initiateMedicationGuardInfo = function() {
  var $sidebar = $("#sidebarMultiButton");
  var processStep = $sidebar.data("processStep");
  var patientId = $sidebar.data("patientId");
  var drugId = $sidebar.data("drugId");
  var statusId = $sidebar.data("statusId");

  showLoader("buttonMedGuard");

  var $form = $("#formulier");

  var formData = $form.serialize();

  if (typeof $form.data("deferredSubmit") === "undefined") {
    $form.data("deferredSubmit", []);
  }

  var initiateMedicationGuardDeferred = $.Deferred();
  $form.data("initiateMedGuardDeferred", initiateMedicationGuardDeferred);

  initiateMedicationGuardPromiseIndex = $form.data("deferredSubmit").push(initiateMedicationGuardDeferred) - 1;

  if (typeof statusId !== "undefined" && statusId !== '') {
    var postUrl = "/status/initiatemedicationguarddosagesignals/" +
      processStep + "/" +
      patientId + "/" +
      drugId + "/" +
      statusId;
  } else {
    var postUrl = "/status/initiatemedicationguarddosagesignals/" +
      processStep + "/" +
      patientId + "/" +
      drugId;
  }

  window.initiateMedicationGuardHandle = ajax.post(postUrl, {
    data: formData,
    success: function (result) {
      handleUpdateMedicationGuardResponse(result);
      initiateMedicationGuardDeferred.resolve();
    }
  });
};

window.addSignal = function(signalPartial) {
  $("#medicationGuardSignals").append(signalPartial);
};

window.removeSignal = function(signalId) {
  $("#medicationGuardSignals ." + signalId).remove();
};

window.startChat = function (subject, patientId) {
  addAjaxLoader();
  var formData = [];
  formData.push({ name: "subject", value: subject });
  ajax.post('/status/startChatFromSignal/' + patientId, {
    data: formData,
    success: processAjaxUpdate
  });
}
