'use strict';

(function() {
  function MedimoKeyboardNavigation() {
    this.availableObjects = [];
    this.activeIndex = 0;
    this.enabled = undefined;
    this.keystrokesBinded = false;
  }

  MedimoKeyboardNavigation.prototype.buildAvailableObjects = function(ev) {
    // could be ran from event, in that case 'this' is out of context
    var kbnNav = window.medimo.KeyboardNavigation;

    kbnNav.availableObjects = [];
    kbnNav.activeIndex = 0;

    // if we're on an ipad or android tablet/phone, there's really no use
    // in enabling keyboard navigation is there?
    if (
      navigator.platform.indexOf("iPad") != -1 ||
      navigator.platform.indexOf("Android") != -1 || // some android phones / tablets
      navigator.platform.indexOf("Mobile Safari") != -1 || // some android phones / tablets
      navigator.platform.indexOf("iPhone") != -1
    ) {
      return (kbnNav.enabled = false);
    }

    var $navElements = $("ul.navigation li");
    var navType = 'ulnav';
    if ($navElements.length === 0) {
      $navElements = $(".dataTable").find("tr:has(td:visible>a)");
      navType = 'dtbls';
    }
    if ($navElements.length === 0) {
      $navElements = $(".dataTable").find("tr.keyboard-item");
      navType = 'dtKeyboardItem';
    }

    if ($navElements.length > 0) {
      if (kbnNav.enabled === undefined) {
        // Only enable when enabled is not set yet
        kbnNav.enabled = true;
      }
      $navElements.each(function (index, value) {
        kbnNav.availableObjects.push({
          type: navType,
          element: value,
          active: false
        });
      });
    }

    kbnNav.preSelectFirst();

    if (kbnNav.keystrokesBinded == false && kbnNav.enabled == true) {
      jQuery(window).keydown(kbnNav.onKeyDown);

      kbnNav.keystrokesBinded = true;
    }

    return kbnNav.enabled;
  };

  MedimoKeyboardNavigation.prototype.preSelectFirst = function() {
    if (this.availableObjects.length <= 0) {
      return false;
    }

    this.makeActive(0);
  };

  MedimoKeyboardNavigation.prototype.makeActive = function(index) {
    if (typeof this.availableObjects[index] === "undefined") {
      return false;
    }

    for (var ndx in this.availableObjects) {
      this.availableObjects[ndx].active = false;
      jQuery(this.availableObjects[ndx].element).removeClass("kbn_active");
    }

    this.availableObjects[index].active = true;
    jQuery(this.availableObjects[index].element).addClass("kbn_active");

    this.activeIndex = parseInt(index);
  };

  MedimoKeyboardNavigation.prototype.onKeyDown = function(ev) {
    if (window.medimo.KeyboardNavigation.enabled != true) {
      return true;
    }
    switch (ev.which) {
      case 40:
        window.medimo.KeyboardNavigation.moveCursor("down", ev);
        break; // DOWN
      case 38:
        window.medimo.KeyboardNavigation.moveCursor("up", ev);
        break; // UP
      case 37:
        window.medimo.KeyboardNavigation.moveCursor("left", ev);
        break; // LEFT
      case 39:
        window.medimo.KeyboardNavigation.moveCursor("right", ev);
        break; // RIGHT
      case 13:
        window.medimo.KeyboardNavigation.select(ev);
        break; // ENTER
      case 32:
        window.medimo.KeyboardNavigation.check(ev);
        break; // SPACE
    }
  };

  MedimoKeyboardNavigation.prototype.calculateIndex = function(index) {
    var columnHeightMargin = 5;
    if (typeof this.availableObjects[this.activeIndex] === "undefined") {
      return false;
    }

    var currentElement = this.availableObjects[this.activeIndex].element;
    var testIndex = index == "down" || index == "right" ? 1 : -1;
    var newIndex = this.activeIndex + testIndex;

    if (newIndex > this.availableObjects.length - 1) {
      newIndex = this.availableObjects.length - 1;
    }

    if (newIndex < 0) {
      newIndex = 0;
    }

    if (typeof this.availableObjects[newIndex] === "undefined") {
      return false;
    }
    var testElement = this.availableObjects[newIndex].element;
    var currentOffset = $(currentElement).offset();
    var testOffset = $(testElement).offset();

    // test if the desired element is somewhat on the desired same height
    if (
      (currentOffset.top >= testOffset.top - columnHeightMargin ||
        currentOffset.top < testOffset.top + columnHeightMargin) &&
      typeof $(currentElement).css("float") != "undefined" &&
      ($(currentElement).css("float") == "left" ||
        $(currentElement).css("float") == "right")
    ) {
      if (index == "down" || index == "up") {
        var columns = Math.floor(
          $(currentElement).parent().width() / $(currentElement).width()
        );
        var steps = (index == "down" ? 1 : -1) * columns;

        var maxIndex = this.availableObjects.length - 1, remainder;
        if (this.activeIndex + steps > this.availableObjects.length - 1) {
          return false;
        }

        if (this.activeIndex + steps < 0) {
          return false;
        }

        return this.activeIndex + steps;
      } else {
        return newIndex;
      }
    } else {
      // disable right and left on non-column situations
      if (index == "right" || index == "left") {
        return false;
      }
    }
    return newIndex;
  };

  MedimoKeyboardNavigation.prototype.moveCursor = function(givenIndex, ev) {
    var index = this.calculateIndex(givenIndex);

    if (index === false) {
      return; // do nothing
    }

    var node = this.availableObjects[this.activeIndex];
    var $element = $(node.element);
    $element.closest('.dataTables_scrollBody').trigger('cursormoved.keyboardnavigation.medimo', $element);

    ev.preventDefault();
    ev.stopPropagation();

    this.makeActive(index);
  };

  MedimoKeyboardNavigation.prototype.select = function(ev) {
    if (!this.isVisibleElement(this.activeIndex)) {
      return;
    }

    var node = this.availableObjects[this.activeIndex];
    var $element = $(node.element);
    var $link;

    $element.trigger('select.keyboardnavigation.medimo');

    $link = null;
    switch (node.type) {
      case "ulnav":
        $link = $element.find("a");
        break;

      case "dtbls":
        $link = $element.find("td>a");
        break;
    }

    if ($link !== null && $link.length !== 0) {
      // trigger click on native element
      $link.get(0).click();
    }

    ev.preventDefault();
    ev.stopPropagation();
  };

  MedimoKeyboardNavigation.prototype.check = function (event) {
    if (!this.isVisibleElement(this.activeIndex)) {
      return;
    }
    var node = this.availableObjects[this.activeIndex];
    $(node.element).trigger('check.keyboardnavigation.medimo', event);
  };

  MedimoKeyboardNavigation.prototype.isVisibleElement = function (index) {
    return typeof this.availableObjects[index] !== "undefined"
      && typeof this.availableObjects[index].element !== "undefined"
      && $(this.availableObjects[index].element).is(":visible") === true;
  };

  window.medimo.KeyboardNavigation = new MedimoKeyboardNavigation();

  $(document).ready(function() {
    // init.dt is only needed because we show the datatable not before init.dt is called in initiatedatatable.js.
    // draw.dt is fired before init.dt. On the first draw.dt event the datatable is still hidden, and keyboardnav
    // would not see any elements.
    $(window).on('draw.dt init.dt build.kbnnav', window.medimo.KeyboardNavigation.buildAvailableObjects);
    window.medimo.KeyboardNavigation.buildAvailableObjects();
  });
})();
