'use strict';

window.processAjaxUpdate = function (jsonResult) {
  // In case of a string result we use the jsonResult and put it into the #main element.
  // This is a workaround for some legacy call the return a direct html output when not
  // following the happy flow (when a resubmit is needed or and error occurred)
  if (typeof jsonResult == "string") {
    removeAjaxLoader();
    var target = "#main";
    var $myResult = $(target);

    if ($myResult.length > 0) {
      // show data
      $myResult.html(jsonResult);
    }
    return;
  }

  for (var i = 0; i < jsonResult.length; i++) {
    var response = jsonResult[i];

    switch (response.responseType) {
      case 'Ok':
        removeAjaxLoader();
        break;

      case "BrowserHistoryGo":
        history.go(response.redirectAmount * 1);
        break;

      case 'ClosePopup':
        var obj = $('#popupOverlay');
        if (obj.length > 0) {
          closePopup();
        }
        break;

      case 'Download':
        saveFile(response.base64Data, response.mimeType, response.fileName);
        removeAjaxLoader();
        break;

      case 'Event':
        var name = response.name;
        $(window).trigger(name, {});
        break;

      case "BrowserRefresh":
        document.location.reload(true);
        break;

      case "BrowserLocation":
        // removeAjaxLoader(); // needed when in safari clicking the previous page button.
        document.location = response.location;
        break;

      case "BrowserTabOpen":
        removeAjaxLoader();
        tabOpen(response.location);
        break;

      case "BrowserWindowOpen":
        removeAjaxLoader();
        window.open(response.location);
        break;

      case 'TabRefresh':
        removeAjaxLoader();
        tabReload(response.tabIdentifier);
        tabChangeTitle(response.tabIdentifier, response.title);
        break;

      case "Notification":
        removeAjaxLoader();
        window.notifier.show(response.notification.type, response.notification.text);
        break;

      case "DomChange":
        removeAjaxLoader();
        var obj = $(response.element);
        if (response.changeType == "hide") obj.hide();
        if (response.changeType == "show") obj.show();
        break;

      case "DirectPrint":
        removeAjaxLoader();
        var obj = $("#directPrint");
        if (obj.length > 0) {
          obj.remove();
        }
        addPrintJob(response.url);
        break;

      case "Popup":
        removeAjaxLoader();
        var obj = $("#popupOverlay");
        if (obj.length > 0) {
          obj.remove();
        }
        addPopup(response.content, response.submitOrCloseOnEnter);
        break;

      case "DTReload":
        removeAjaxLoader();
        $("table.dataTable:visible").DataTable().ajax.reload();
        break;

      case "Content":
        removeAjaxLoader();
        var target;
        if (response.target == undefined) {
          target = "#main";
        } else {
          target = response.target;
        }

        var $myResult = $(target);

        if ($myResult.length > 0) {
          // show data
          $myResult.html(response.html);
        }
        break;

      case "AppendContent":
        removeAjaxLoader();
        var target;
        if (response.target == undefined) {
          target = "#main";
        } else {
          target = response.target;
        }

        var $myResult = $(target);

        if ($myResult.length > 0) {
          // show data
          $myResult.append(response.html);
        }
        break;

      case "SetHiddenValue":
        removeAjaxLoader();
        var target;
        target = response.target;

        var $myResult = $(target);

        if ($myResult.length > 0) {
          // set data
          $myResult.val(response.data);
        }
        break;

      case "AddClass":
        removeAjaxLoader();
        var target;
        target = response.target;

        var $myResult = $(target);

        if ($myResult.length > 0) {
          // set data
          $myResult.addClass(response["class"]);
        }
        break;

      case "RemoveClass":
        removeAjaxLoader();
        var target;
        target = response.target;

        var $myResult = $(target);

        if ($myResult.length > 0) {
          // set data
          $myResult.removeClass(response["class"]);
        }
        break;

      case "ShowElement":
        removeAjaxLoader();
        var target;
        target = response.target;

        var $myResult = $(target);

        if ($myResult.length > 0) {
          // set data
          $myResult.show();
        }
        break;

      case "HideElement":
        removeAjaxLoader();
        var target;
        target = response.target;

        var $myResult = $(target);

        if ($myResult.length > 0) {
          // set data
          $myResult.hide();
        }
        break;

      case "CaptureFrontendReport":
        if (typeof Bugsnag != "undefined") {
          var errortitle = response.error;
          var errormessage = response.message;
          var captures = [];

          if (
            typeof response.captureselectors != "undefined" &&
            response.captureselectors.length > 0
          ) {
            for (var selectorIndex in response.captureselectors) {
              var selector = response.captureselectors[selectorIndex];
              var $selector = $(selector);
              for (var index in $selector) {
                var element = $selector[index];
                captures.push(element.outerHTML);
              }
            }
          }

          Bugsnag.notifyException(new Error(errortitle), {
            groupingHash: errortitle,
            message: errormessage,
            response: response,
            captureselectors: captures
          });
        }
        break;

      case "AddSignal":
        var functionData = response.functionData;
        addSignal(functionData);
        break;

      case "RemoveSignal":
        var functionData = response.functionData;
        removeSignal(functionData);
        break;

      case "UpdateVueManualSchema":
        var state = response.state;
        var times = response.times;
        var dates = response.dates;

        //Generate event that VueJs is listening to
        $(document).trigger('medimo.updateVueManualSchema', {'state': state, 'times': times, 'dates': dates});
        break;

      case "InputAddRedBorder":
        var elemId = response.elemId;
        // alert('add red border');
        $('#' + elemId).css('border', '1px solid #cd0a0a');
        break;

      case "InputRemoveBorder":
        var elemId = response.elemId;
        // alert('remove red border');
        $('#' + elemId).css('border', '');
        break;
    }

    // Fire an event with the response, so we can catch it and respond to it in our code.
    $(document).trigger('FormAjax'+response.responseType, response);
  }
};

window.isOneDeferredSubmitPending = function (deferredSubmits) {
  //check if there are pending one's
  for (var i in deferredSubmits) {
    /**
     * note to devs; never use jQuery.each here --
     * it's loop iterator is called a-sync, therefor you can't
     * rely on it's result a couple of lines down.
     */
    if (typeof deferredSubmits[i].state == "function" && deferredSubmits[i].state() == "pending") {
      return true;
    }
  }

  return false;
};

window.addAjaxLoader = function () {
  var loader = $(".ajaxloader");
  if (loader.length === 0) {
    $("body").append('<div class="ajaxloader">' +
      '<div style="position:relative;">' +
      '<img class="medimo-icon-is-spinning mx-auto my-auto" src="/dist/assets/loader.svg" :style="dimensions" alt="">' +
      '</div>' +
      '<div>');
  }
  //remove focus from current element
  var focused = $(':focus');
  focused.blur();

  return focused;
};

function addPrintJob($url) {
  $("body").append(
    '<iframe id="directPrint" src="' +
    $url +
    '" style="height:0px; position: absolute;">'
  );
};

window.addPopup = function ($content, submitOrCloseOnEnter = true) {
  scrollLock();
  const $popup = $('<div id="popupOverlay"/>');

  // add submitOrCloseOnEnter to popup data, so we can fetch it later
  // when a key is pressed and we check if we should close the popup
  $popup.data('submitOrCloseOnEnter', submitOrCloseOnEnter);

  // Hide popup overlay element
  $popup.css("opacity", 0);

  // Add html ($content) and append $popup to the body
  $popup.html($content);
  $("body").append($popup);

  window.auto_resize_formajaxprocessor_modal();

  // Show the popup overlay element
  $popup.css("opacity", 1);

  // Store if keyboard nav was on or off, and the closePopupHandler in the $popup's data,
  // so we can reach it when we want to close the popup with closePopup()
  $popup.data('kbNavOld', window.medimo.KeyboardNavigation.enabled);

  // Turn off keyboard navigation
  window.medimo.KeyboardNavigation.enabled = false;

  $popup.on('click', 'a.ajax-load', function (ev) {
    ev.preventDefault();
    ajaxLoadUrl($(this).attr('href'));
  });

  // Close popup on enter (char 13), escape (char 27) or when clicking on element with class 'close'
  document.addEventListener('keydown', closePopupHandler);

  $popup.on("click", "a.close", closePopupHandler);

  $popup.find('.hideZero').hideZero();
};

function closePopupHandler(event) {
  const $popup = $('#popupOverlay');
  const submitOrCloseOnEnter = $popup.data('submitOrCloseOnEnter');

  // We need an event without a keyCode (a click or tap on a.close) or an event
  // with keyCode 13 (enter) or 27 (escape).
  // If we don't have that, we don't want to close the popup, so return.
  if (keyPressedIsNotSubmitOrCloseKey(event, submitOrCloseOnEnter)) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();

  // Click .submit button in popup on enter, and DON'T close the popup
  if (event.keyCode === 13 && $popup.find('.submit').length > 0) {
    $popup.find('.submit').click();
    return;
  }

  closePopup();

  function keyPressedIsNotSubmitOrCloseKey(event, submitOrCloseOnEnter) {
    const keyCode = event.keyCode;
    return keyCode !== undefined && keyCode !== 27 && (!submitOrCloseOnEnter || keyCode !== 13);
  }
};

window.closePopup = function () {
  const $popup = $('#popupOverlay');

  // Remove event listeners
  document.removeEventListener('keydown', closePopupHandler);

  // Reset keyboard nav
  window.medimo.KeyboardNavigation.enabled = $popup.data('kbNavOld');

  // Remove the popup and all its elements and eventlisteners
  $popup.remove();
  scrollFree();
};

window.deleteResource = function (url, successCallback) {
  addAjaxLoader();
  ajax.delete(url, {
    success: function (response) {
      processAjaxUpdate(response);
      if (successCallback !== undefined) {
        successCallback(response);
      }
    }
  });
};

window.submitForm = function submitForm(obj) {
  $(obj).submit();
};

function submitFormPlusHiddenValues(formWrapper, dataName, dataValue) {
  var formId = formWrapper.attributes.getNamedItem('id').value;
  var postUrl = formWrapper.attributes.getNamedItem('action').value;
  var $form = $("#" + formId);
  $form.append('<input type="hidden" name="' + dataName + '" value="'+ dataValue +'" />');

  var formData = $form.serialize();

  addAjaxLoader();
  ajax.post(postUrl, {
    data: formData,
    success: function (result) {
      processAjaxUpdate(result);
      removeAjaxLoader();
    }
  });
};

  /**
   *
   * @param obj
   * @param submitMethod
   * @param beforeSubmit
   */
window.initiateAjaxForm = function (obj, submitMethod, beforeSubmit, onSuccess) {

    var elementWithFocus;

    obj.ajaxForm({
      success: function (data) {
        processAjaxUpdate(data);

        //Focus back to element with focus before submit
        if (typeof elementWithFocus != "undefined" &&
          typeof elementWithFocus[0] != "undefined") {
          elementWithFocus.focus();
        }

        if (typeof onSuccess !== "undefined") {
          if (
            typeof data[0].notification !== "undefined" &&
            data[0].notification.type == "success"
          ) {
            if (typeof onSuccess === "function") {
              onSuccess();
            }
          }
        }
      },
      type: submitMethod,
      beforeSubmit: function (arr, $form, options) {
        elementWithFocus = addAjaxLoader();

        var formulier = $form;

        /**
         * FEATURE: if there is a $.deferred available on
         * the form's given data() attribute, wait for it to
         * resolve until submitting the form. this allows
         * the local scripts to finish processing before jquery's
         * form submit kicks in.
         */
        var deferredSubmits = $form.data("deferredSubmit");
        if (
          typeof deferredSubmits != "undefined" &&
          deferredSubmits instanceof Array == true
        ) {
          if (deferredSubmits.length > 0) {
            //check if there are pending one's
            if (isOneDeferredSubmitPending(deferredSubmits) === true) {
              jQuery.when.apply($, deferredSubmits).then(function () {

                //if we have an element with focus
                if (elementWithFocus !== undefined && elementWithFocus[0] !== undefined) {

                  //Case 1: user clicked an input button
                  if (elementWithFocus[0].type === 'submit' || elementWithFocus.hasClass('submit')) {
                    elementWithFocus.click();
                  }
                  //Case 2: an input of other type (e.g pressing enter in a text field)
                  //submit with value of first submit button (default)
                  else {
                    var formId = elementWithFocus[0].form.attributes.getNamedItem('id').value;
                    var selectedInputs = $("#" + formId + " :submit");
                    var selectedInput = selectedInputs.first().attr('name');
                    submitFormPlusHiddenValues(elementWithFocus[0].form, selectedInput, true);
                  }
                }
                else if( formulier.data('clickedOn') !== undefined) {
                  //Case 3: Probably we are on a browser that looses focus for input button
                  //Check whether the $form.data('clicked-on') attribute is set
                  //And use that if available
                  var clickedOn = formulier.data('clickedOn');
                  var clickedValue = formulier.data('clickedOnValue');
                  submitFormPlusHiddenValues(formulier[0], clickedOn, clickedValue);
                } else {
                  // In case all above fail, remove the ajaxloader
                  removeAjaxLoader();
                }
              });

              return false;
            }
          }
        }

        if (beforeSubmit != undefined) {
          return beforeSubmit(arr, $form, options);
        }
      }
    });
  };

/**
 * Load a URL with the ajax method and process the result accordingly
 *
 * @param url
 * @param method GET,POST,etc..
 */
window.ajaxLoadUrl = function (url, method) {
  if (method == undefined) {
    method = "GET";
  }

  addAjaxLoader();

  ajax.send(url, {
    method: method,
    success: processAjaxUpdate
  });
};

window.removeAjaxLoader = function () {

  //It might be the case that a deferred Submit is waiting in line
  //In that case we do not want to remove the ajax loader
  //This only happens on prescribe pages, which do have one form element
  var $form = $("form").first();
  if (typeof $form != 'undefined') {
    var deferredSubmits = $form.data("deferredSubmit");
    if (typeof deferredSubmits != "undefined" && deferredSubmits instanceof Array && deferredSubmits.length > 0) {
      if (isOneDeferredSubmitPending(deferredSubmits)) {
        return;
      }
    }
  }

  var loader = $(".ajaxloader");
  if (loader.length > 0) {
    loader.remove();
  }
};

// Find <a> tags that should be ajax loaded.
$(function () {
  $("#main").on("click", "a.ajax-load", function (ev) {
    ev.preventDefault();

    if ($(this).data('href') != undefined) {
      $(this).attr('href', $(this).data('href'));
    }

    ajaxLoadUrl($(this).attr("href"));
  });
});
