'use strict';

if (typeof window === 'object') {
  window.ConnectionManager = ConnectionManager;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = ConnectionManager;
}

/**
 * Class ConnectionManager
 *
 * Manages connections
 *
 * @class
 * @public
 * @constructor
 * @params {Notifier} notifier
 * @params {ErrorReporter} errorReporter
 * @returns {undefined}
 */
function ConnectionManager(notifier, errorReporter) {
  /**
   * @private
   * @type {ErrorReporter}
   */
  this._errorReporter = errorReporter;

  /**
   * @private
   * @type {Notifier}
   */
  this._notifier = notifier;

  /**
   * @private
   * @type {boolean}
   */
  this._pageIsUnloading = false;

  // We want to know if the page is unloading, so we can ignore failed ajax requests
  window.addEventListener('beforeunload', this.pageIsUnloading.bind(this));
}

/**
 * Manage a failed ajax request
 *
 * @public
 * @param {string} url
 * @param {Object} ajaxOptions
 * @param {Object} jqXhr
 * @returns {undefined}
 */
ConnectionManager.prototype.ajaxRequestFailed = function (url, ajaxOptions, jqXhr) {
  // Don't notify / report when unloading a page or when the request is
  // 'cancelled' or 'aborted' (both are jQuery values) or when this was a CORS request.
  if (this._pageIsUnloading || jqXhr.statusText === 'canceled' || jqXhr.statusText === 'abort' || this.isCors(url)) {
    return;
  }

  let notification;
  let redirectUrl;

  const readyState = jqXhr.readyState;
  if (readyState === 0) {
    // No connection possible
    notification = 'Er lijkt geen verbinding met Medimo mogelijk. Controleer uw verbinding en probeer opnieuw.';
    // We can remove the ajax loader here, because we are sure nothing was sent to our servers
    removeAjaxLoader();
  } else if (readyState < 4) {
    // Lost connection
    notification = 'De verbinding is verbroken. Herlaad de pagina en probeer zo nodig opnieuw.';
  } else if (jqXhr.status === 401) {
    notification = 'Ongeautoriseerd verzoek. U wordt automatisch uitgelogd.';
    redirectUrl = '/logout';
  } else if (jqXhr.status >= 400 && jqXhr.status < 500) {
    // Status codes 4xx
    if (typeof jqXhr.responseJSON !== 'undefined') {
      notification = jqXhr.responseJSON;
    } else if (typeof jqXhr.responseText !== 'undefined') {
      // Deze is minder preferabel omdat die minder formatted is dan de JSON variant
      // maar altijd nog meer info bevat dan de generieke versie hieronder
      // Just in case dat responseJSON leeg is, maar responseText niet
      notification = jqXhr.responseText;
    } else {
      notification = 'We hebben een ongeldig verzoek ontvangen. Herlaad de pagina en probeer zo nodig opnieuw.';
    }
  } else {
    // and 5xx
    notification = 'Er is een onbekende fout opgetreden. Herlaad de pagina en probeer zo nodig opnieuw.';
    this._errorReporter.reportAjaxError(url, ajaxOptions, jqXhr);
  }

  this._notifier.showError(notification);

  if (redirectUrl) {
    window.medimo.allowNavigationOnUnsavedChanges = true; // app.js checks this value when the user changes pages but he/she has important changes.
    window.location.href = redirectUrl;
  }
};

/**
 * Check if the url is a CORS url.
 *
 * @public
 * @param {string} url
 * @returns {boolean}
 */
ConnectionManager.prototype.isCors = function (url) {
  // Check if the url has a host.
  if ((url[0] === '/' && url[1] !== '/') || url[0] === '?') {
    // Url starts with / and then a different character,
    // or url starts with ? (query string only)
    // so the url does not include the host and this is not CORS.
    return false;
  }

  // Check host.
  // Strip protocol first (and store it), so we can grab the host from the stripped string.
  let protocol = 'http:';
  let protocolStrippedUrl = url;
  const lowerCaseUrl = url.toLowerCase();
  if (lowerCaseUrl.indexOf('http://') === 0) {
    // Url starts with http://
    protocolStrippedUrl = url.substr(7);
  } else if (lowerCaseUrl.indexOf('https://') === 0) {
    // Url starts with https://
    protocol = 'https:';
    protocolStrippedUrl = url.substr(8);
  } else if (lowerCaseUrl.indexOf('//') === 0) {
    // Url starts with //, so if the host is the same as window.location.host, the browser uses window.location.protocol
    // as the protocol.
    protocolStrippedUrl = url.substr(2);
    protocol = window.location.protocol;
  }

  // Check if the url host matches the window's host.
  const host = protocolStrippedUrl.split('/')[0];
  if (host !== window.location.host) {
    // Url host does not match the window's host, so this is CORS.
    // host and window.location.host include a non standard port, so we implicitly check for the port here as well.
    return true;
  }

  // Check protocol.
  if (protocol !== window.location.protocol) {
    // Url protocol does not match the window's protocol, so this is CORS.
    return true;
  }

  // Protocol and host are the same, so this is not CORS.
  return false;
};

/**
 * Set _pageIsUnloading to true, so we can ignore failing ajax requests because of unloading the page
 *
 * @public
 * @returns {undefined}
 */
ConnectionManager.prototype.pageIsUnloading = function () {
  this._pageIsUnloading = true;
};
