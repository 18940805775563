'use strict';

/**
 * Class FileSaver
 *
 * Saves base64 encode data to disk.
 */
class FileSaver {
  /**
   * Make the browser 'download' the file.
   *
   * @public
   * @param {string} base64Data
   * @param {string} mimeType
   * @param {string} fileName
   * @returns {undefined}
   */
  saveAs(base64Data, mimeType, fileName) {
    // Create a Blob from the data
    const decodedData = atob(base64Data);
    const buffer = new ArrayBuffer(decodedData.length);
    const uint8 = new Uint8Array(buffer);
    for (let i = 0; i != decodedData.length; ++i) {
      uint8[i] = decodedData.charCodeAt(i) & 0xFF; // Hacky cast to binary
    }
    const blob = new Blob([uint8], { type: mimeType });

    if (navigator.msSaveOrOpenBlob) { // For IE we use 'msSaveOrOpenBlob'
      navigator.msSaveOrOpenBlob(blob, fileName);
    } else { // For other browsers we create the download by creating an anchor element and clicking on it
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.setAttribute('href', objectUrl);
      a.setAttribute('download', fileName);
      a.setAttribute('style', 'display: none');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    }
  }
}

if (typeof window === 'object') {
  window.FileSaver = FileSaver;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = FileSaver;
}
