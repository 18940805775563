'use strict';

const CLIENT_EVENT_JOIN = 'join';
const CLIENT_EVENT_LEAVE = 'leave';
const PRESENCE_SUFFIX = '.presence';
const SERVER_PRESENCE_EVENT_JOINED = 'joined';
const SERVER_PRESENCE_EVENT_LEFT = 'left';

/**
 * Use to instantiate a websocket connection, join a channel and listen for events.
 * NOTE! Only instantiate once on a page! app.js will set an instance to window.medimo.WebSocket.
 */
class WebSocketConnector {
  /**
   * @param {Socket.io} io Socket.io client create method.
   * @param {String} token Token for websocket server connection.
   */
  constructor(io, token) {
    this._io = io;
    this._token = token;
    this._socket = null;
  }

  /**
   * Join a channel.
   *
   * @param {String} channelName
   * @returns {Channel}
   */
  join(channelName) {
    return this._join(channelName, (channelName, socket) => new Channel(channelName, socket));
  }

  /**
   * Join a presence channel.
   *
   * @param {String} channelName
   * @param {boolean} asGhost
   * @returns {PresenceChannel}
   */
  joinPresence(channelName, asGhost = false) {
    return this._join(channelName, (channelName, socket) => new PresenceChannel(channelName, socket, asGhost));
  }

  /**
   * @callback createChannelCallback
   * @param {string} channelName
   * @param {Socket} socket
   */

  /**
   * @param {string} channelName
   * @param {Function} createChannelCallback
   * @returns {Channel|PresenceChannel}
   * @private
   */
  _join(channelName, createChannelCallback) {
    const socket = this._connectedSocket();
    let channel = socket.channels.get(channelName);
    if (!channel) {
      channel = createChannelCallback(channelName, socket);
    }
    channel.join(); // Channel won't join again when already joined.
    return channel;
  }

  /**
   * @private
   * @returns {Socket}
   */
  _connectedSocket() {
    if (this._socket === null) {
      this._socket = this._connect();
      this._socket.channels = new Map(); // Keep track of channels in socket, so Websocket and Channel have access to it.
    } else if (!this._socket.isConnecting && this._socket.disconnected) {
      this._socket.connect();
    }
    return this._socket;
  }

  /**
   * Connects the websocket.
   *
   * @returns {Socket}
   */
  _connect() {
    const socket = this._io({
      path: '/ws',
      transports: ['websocket'], // disable http long polling
      query: 'token=' + this._token,
      // So the websocket is not closed on the beforeunload event in the browser when opening a protocol handler like zorgid://.
      // This will result in the client in Chrome not emitting a disconnect event when leaving the page, see https://github.com/socketio/socket.io/issues/3639.
      closeOnBeforeunload: false,
    });

    socket.isConnecting = true;

    socket.on('connect', () => {
      socket.isConnecting = false;
    });

    socket.on('connect_error', () => {
      socket.isConnecting = false;
    });

    // Join channels on reconnect.
    socket.io.on('reconnect', () => {
      socket.channels.forEach(channel => channel.rejoin());
    });

    return socket;
  }
}

class Channel {
  /**
   * @param {String} name
   * @param {Socket} socket
   * @returns {void}
   */
  constructor(name, socket) {
    this._name = name;
    this._socket = socket;
    this._listeners = new Set();
    this._isJoined = false;
  }

  /**
   * @returns {boolean}
   */
  isJoined() {
    return this._isJoined;
  }

  /**
   * Join the channel.
   *
   * @returns {void}
   */
  join() {
    if (this._socket.channels.get(this._name)?._isJoined) {
      return; // Already joined this channel.
    }

    this._socket.channels.set(this._name, this); // To know which channels to rejoin on an unexpected disconnect/reconnect.
    this._isJoined = true; // Flag as joined.
    this._emit(CLIENT_EVENT_JOIN); // Let websocket server know we're joining.
  }

  /**
   * Rejoin the channel after unexpected disconnect.
   *
   * @returns {void}
   */
  rejoin() {
    if (this._isJoined) {
      this._emit(CLIENT_EVENT_JOIN);
    }
  }

  /**
   * @protected
   * @param event
   * @returns {void}
   */
  _emit(event) {
    this._socket.emit(event, this._name);
  }

  /**
   * Leave the channel.
   *
   * @returns {void}
   */
  leave() {
    if (!this._socket.channels.get(this._name)?._isJoined) {
      return; // Already left channel.
    }

    // Stop event listeners, so they won't be bound when joining the channel again.
    this._listeners.forEach((listener) => {
      this._socket.off(listener.eventName, listener.handler);
    });

    this._socket.channels.delete(this._name); // Remove channel from socket, so we don't rejoin it on an eventual reconnect.
    this._emit(CLIENT_EVENT_LEAVE); // Let websocket server know we're leaving.
    this._isJoined = false; // Flag as left for code that still has a a reference to this instance after leaving.

    // Disconnect websocket when no channels are left.
    if (this._socket.channels.size === 0 && this._socket.connected) {
      this._socket.disconnect();
    }
  }

  /**
   * On incoming event.
   *
   * @param {String} event
   * @param {Function} callback
   * @returns {void}
   */
  on(event, callback) {
    const eventName = `${event}@${this._name}`;
    const handler = (message) => callback(message);

    // Keep track of listeners, so we can unbind when leaving the channel.
    this._listeners.add({
      eventName: eventName,
      handler: handler,
    });

    // Bind the handler to the event.
    this._socket.on(eventName, handler);
  }

  /**
   * Turn of all listeners for event.
   *
   * @param {String} event
   * @returns {void}
   */
  off(event) {
    const eventName = `${event}@${this._name}`;
    this._listeners.forEach((listener) => {
      if (listener.eventName === eventName) {
        this._socket.off(listener.eventName, listener.handler);
      }
    });
  }
}

/**
 * A channel that is notified on joining and leaving members.
 */
class PresenceChannel extends Channel {
  /**
   * @param {String} name
   * @param {Socket} socket
   * @param {boolean} isGhost
   * @returns {void}
   */
  constructor(name, socket, isGhost = false) {
    super(`${name}${PRESENCE_SUFFIX}`, socket);
    this._presence = 0; // Keeps track of presence in channel.
    this._isGhost = isGhost;
  }

  /**
   * @returns {int}
   */
  presence() {
    return this._presence;
  }

  /**
   * @returns {boolean}
   */
  isGhost() {
    return this._isGhost;
  }

  /**
   * Handle when a member joined the presence channel.
   *
   * @param {Function<int>} callback
   * @returns {void}
   */
  onJoined(callback) {
    this.on(SERVER_PRESENCE_EVENT_JOINED, (message) => this._handleServerMessage(message, callback));
  }

  /**
   * Handle when a member left the presence channel.
   *
   * @param {Function<int>} callback
   * @returns {void}
   */
  onLeft(callback) {
    this.on(SERVER_PRESENCE_EVENT_LEFT, (message) => this._handleServerMessage(message, callback));
  }

  /**
   * @private
   * @param {Object} message
   * @param {Function<int>} callback
   */
  _handleServerMessage(message, callback) {
    this._presence = message.presence;
    callback(this._presence);
  }

  /**
   * @protected
   * @param event
   * @returns {void}
   */
  _emit(event) {
    this._socket.emit(event, this._name, this._isGhost); // Add isGhost on every emit.
  }
}

if (typeof window === 'object') {
  window.WebSocketConnector = WebSocketConnector;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = WebSocketConnector;
}
