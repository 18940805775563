'use strict';

const $ = window.$;
window.initializeDataTable = function (objList, objFilterField, objPaginate, defaultSearch, defaultOrder, options) {
  if (typeof (objPaginate) === 'undefined') objPaginate = true;
  if (typeof (defaultOrder) === 'undefined') defaultOrder = [1, "asc"];

  // get searchValue from breadcrumb client side stored
  if (defaultSearch === '') {
    if (window.medimo.Breadcrumb) {
      defaultSearch = window.medimo.Breadcrumb.getCurrentSearchValue();
    }
  }

  const defaultOptions = {
    'language': {
      'processing': '<i class="fa fa-refresh fa-spin"></i> Bezig...',
      'lengthMenu': '_MENU_ resultaten weergeven',
      'zeroRecords': 'Geen resultaten gevonden',
      'info': '_START_ tot _END_ van _TOTAL_ resultaten',
      'infoEmpty': 'Geen resultaten om weer te geven',
      'infoFiltered': ' (gefilterd uit _MAX_ resultaten)',
      'infoPostFix': '',
      'search': 'Zoeken:',
      'emptyTable': 'Geen resultaten aanwezig in de tabel',
      'infoThousands': '.',
      'loadingRecords': 'Een moment geduld aub - bezig met laden...',
      'paginate': {
        'first': 'Eerste',
        'last': 'Laatste',
        'next': 'Volgende',
        'previous': 'Vorige'
      }
    },
    'paginate': objPaginate,
    'displayLength': 10,
    'scrollY': '403px',
    "aoColumnDefs": [
      {'bSortable': false, 'aTargets': [0]},
      {'sWidth': '0px', 'aTargets': 0}
    ],
    "order": defaultOrder,
    dom: 'rtip'
  };

  // used for calculating which page to open
  if (window.medimo.Breadcrumb) {
    defaultOptions.displayStart = window.medimo.Breadcrumb.getCurrentDisplayStartValue();
  }

  //Override options if an option argument is set
  if (typeof (options) !== 'undefined') {
    for (const key in options) {
      defaultOptions[key] = options[key];
    }
  }

  // merge-in user config
  if (typeof window.medimo.DataTables.userOverrideConfigFrontend !== 'undefined') {
    options = $.extend(options, window.medimo.DataTables.userOverrideConfigFrontend);
  }

  let $oTable = $(objList).dataTable(defaultOptions);


  /**
   * When there is a default search, use it as the first searchquery
   * and put the text into the searchfield.
   */
  if (typeof defaultSearch == 'string' && defaultSearch.length > 0) {
    options.oSearch = {"sSearch": defaultSearch};

    const searchInput = $(objFilterField);
    searchInput.val(defaultSearch);
    const strLength = defaultSearch.length * 2;

    searchInput.focus();
    searchInput[0].setSelectionRange(strLength, strLength);

    $oTable.fnFilter(defaultSearch);
  }

  if (typeof objFilterField != 'undefined') {
    let prevInput = $(objFilterField).val();
    $(objFilterField).data('otableref', $oTable);
    setUpNavBackOnBackspaceIn($(objFilterField));
    $(objFilterField).on('keyup', function (e) {
      if (e.which == 13 && window.medimo.KeyboardNavigation.enabled == false) { // enter / return
        $(objFilterField).blur(); // remove focus so on-screen keyboard hides
      }

      if (prevInput == $(this).val()) {
        return true;
      }

      prevInput = $(this).val();

      $oTable = $(this).data('otableref');
      $oTable.fnFilter($(this).val());
    });
  }

  return $oTable;
};

window.initializeDataTableLog = function (objList, objFilterField) {
  let options = {
    'language': {
      'processing': 'Bezig...',
      'lengthMenu': '_MENU_ resultaten weergeven',
      'zeroRecords': 'Geen resultaten gevonden',
      'info': '_START_ tot _END_ van _TOTAL_ resultaten',
      'infoEmpty': 'Geen resultaten om weer te geven',
      'infoFiltered': ' (gefilterd uit _MAX_ resultaten)',
      'infoPostFix': '',
      'search': 'Zoeken:',
      'emptyTable': 'Geen resultaten aanwezig in de tabel',
      'infoThousands': '.',
      'loadingRecords': 'Een moment geduld aub - bezig met laden...',
      'paginate': {
        'first': 'Eerste',
        'last': 'Laatste',
        'next': 'Volgende',
        'previous': 'Vorige'
      }
    },
    'paginate': true,
    'displayLength': 10,
    'dom': 'tp',
    "aoColumnDefs": [
      {'bSortable': false, 'aTargets': [0]}
    ],
    "order": [[1, "desc"]]

  };

  // merge-in user config
  if (typeof window.medimo.DataTables.userOverrideConfigFrontend !== 'undefined') {
    options = $.extend(options, window.medimo.DataTables.userOverrideConfigFrontend);
  }

  // used for calculating which page to open
  if (window.medimo.Breadcrumb) {
    options.displayStart = window.medimo.Breadcrumb.getCurrentDisplayStartValue();
  }

  const oTable = $(objList).dataTable(options);

  $(objFilterField).on('keyup', function (e) {
    if (e.which == 13 && window.medimo.KeyboardNavigation.enabled == false) { // enter / return
      $(objFilterField).blur(); // remove focus so on-screen keyboard hides
    }

    oTable.fnFilter($(this).val());
  });
};

/**
 * This method can work with a datatables view with 1 search field, or with multiple search/input fields.
 * If you have just 1 search field, pass the jquery selector string of it as "objPrimaryInputField". If you want to use
 * multiple fields, give them all the same class and pass that class name as a jquery selector string to
 * "inputFieldSelector". You should still pass "objPrimaryInputField". This "multiple fields feature" works with input text
 * elements, select elements and input text elements that have a javascript datetimepicker jquery instance on them.
 *
 * @param endpoint URL for the AJAX call
 * @param columns List with columns
 * @param objList
 * @param objPrimaryInputField
 * @param objExtraActionKeys
 * @param defaultSearch
 * @param defaultOrder
 * @param inputFieldSelector
 * @param {Object} phpControllerOptions Options coming from the php controller
 */
window.initializeDataTableServerSide = function (endpoint, columns, objList, objPrimaryInputField, objExtraActionKeys, defaultSearch, defaultOrder, inputFieldSelector, phpControllerOptions) {
  // get searchValue from breadcrumb client side stored
  if (defaultSearch === '') {
    if (window.medimo.Breadcrumb) {
      defaultSearch = window.medimo.Breadcrumb.getCurrentSearchValue();
    }
  }

  if (typeof (defaultOrder) === 'undefined') {
    defaultOrder = [1, "asc"];
  }

  const $inputFields = 0 < $(inputFieldSelector).length ? $(inputFieldSelector) : null;
  const isMultiInput = $inputFields !== null;
  let searchTimeout;

  let options = {
    "processing": true,
    "serverSide": true,
    "ajax": endpoint,
    'deferRender': true,
    'language': {
      'processing': '<i class="fa fa-refresh fa-spin"></i> Bezig...',
      'lengthMenu': '_MENU_ resultaten weergeven',
      'zeroRecords': 'Geen resultaten gevonden',
      'info': '_START_ tot _END_ van _TOTAL_ resultaten',
      'infoEmpty': 'Geen resultaten om weer te geven',
      'infoFiltered': ' (gefilterd uit _MAX_ resultaten)',
      'infoPostFix': '',
      'search': 'Zoeken:',
      'emptyTable': 'Geen resultaten aanwezig in de tabel',
      'infoThousands': '.',
      'loadingRecords': 'Een moment geduld aub - bezig met laden...',
      'paginate': {
        'first': 'Eerste',
        'last': 'Laatste',
        'next': 'Volgende',
        'previous': 'Vorige'
      }
    },
    'paginate': true,
    'displayLength': 10,
    'scrollY': '403px',
    "aoColumnDefs": [
      {'bSortable': false, 'aTargets': [0]},
      {'sWidth': '0px', 'aTargets': 0}
    ],
    "order": [defaultOrder],
    dom: 'rtip'
  };

  // If we have muli input, we will make datatables search for that through the ajax options
  if (isMultiInput) {
    const url = options.ajax;
    options.ajax = {
      url,
      data: function (data) {
        data['search[value]'] = _toMultiInputData($inputFields);
        return data;
      }
    };
  }

  // Merge in php controller options
  if (phpControllerOptions !== undefined) {
    options = $.extend(options, phpControllerOptions);
  }

  // used for calculating which page to open
  if (window.medimo.Breadcrumb) {
    options.displayStart = window.medimo.Breadcrumb.getCurrentDisplayStartValue();
  }

  options.columns = [];
  options.columns.push({data: "x", name: "x", searchable: false, orderable: false}); //first add the column with the dot
  // User php controller options columns
  if (phpControllerOptions !== undefined && phpControllerOptions.columns instanceof Array) {
    for (let i = 0; i < phpControllerOptions.columns.length; i++) {
      options.columns.push(phpControllerOptions.columns[i]);
    }
    // Set options.columns to default
  } else {
    for (const key in columns) {
      options.columns.push({data: columns[key], name: columns[key], searchable: true, orderable: true});
    }
  }

  if (typeof objExtraActionKeys == 'object' && objExtraActionKeys.length > 0) {
    for (let x = 0; x < objExtraActionKeys.length; x++) {
      options.columns.push({data: objExtraActionKeys[x], name: objExtraActionKeys[x], searchable: false, orderable: false});
    }
  }

  /**
   * When there is a default search, use it as the first searchquery
   * and put the text into the searchfield.
   */
  if (typeof defaultSearch == 'string' && defaultSearch.length > 0) {
    options.oSearch = {"sSearch": defaultSearch};

    const searchInput = $(objPrimaryInputField);
    searchInput.val(defaultSearch);
    const strLength = defaultSearch.length * 2;

    searchInput.focus();
    searchInput[0].setSelectionRange(strLength, strLength);
  }

  // merge-in user config
  if (typeof window.medimo.DataTables.userOverrideConfigBackend !== 'undefined') {
    options = $.extend(options, window.medimo.DataTables.userOverrideConfigBackend);
  }

  // Create the datatables object
  const $oTable = $(objList).dataTable(options);

  $oTable.trigger('datatableInitialized.medimo');

  const $primaryInputField = $(objPrimaryInputField);

  setUpNavBackOnBackspaceIn($primaryInputField);

  // Remove focus on enter (char 13) so on-screen keyboard hides
  $primaryInputField.on('keyup', function (e) {
    if (e.which === 13 && !window.medimo.KeyboardNavigation.enabled) { // enter / return
      $primaryInputField.blur();
    }
  });

  // When we have multi input
  if (isMultiInput) {
    _searchOnMultiInputChange($inputFields);
    // When we have just 1 input element
  } else {
    _searchOnPrimaryInputChange($primaryInputField);
  }

  return $oTable;

  /**
   * Search on value change in one of the $inputFields
   *
   * Check change on nput elements, select elements and datetimepickers
   *
   * @private
   * @param {jQuery} $inputFields
   * @return {undefined}
   */
  function _searchOnMultiInputChange($inputFields) {
    $inputFields.each(function () {
      if (this.tagName === 'INPUT' && this.type === 'text') {
        // Search on change datetimepicker value
        if ($(this).data('xdsoft_datetimepicker')) {
          _onDatetimePickerChange(this, function () {
            _doMultiInputSearch();
          });
          // Search on change normal input field
        } else {
          _onInputTextChange(this, function () {
            _doMultiInputSearch();
          });
        }
        // Search on change select
      } else if (this.tagName === 'SELECT') {
        $(this).change(function () {
          _doMultiInputSearch();
        });
      }
    });
  }

  /**
   * Search on value change of $objPrimaryInputField
   *
   * @private
   * @param {jQuery} $primaryInputField
   * @return {undefined}
   */
  function _searchOnPrimaryInputChange($primaryInputField) {
    _onInputTextChange($primaryInputField[0], function () {
      _doPrimaryInputSearch($primaryInputField);
    });
  }

  /**
   * Build stringified data from $inputFields data
   *
   * @private
   * @param {jQuery} $inputFields
   * @return {string} Stringified json
   */
  function _toMultiInputData($inputFields) {
    const jsonInput = {};
    $inputFields.each(function () {
      jsonInput[this.name] = this.value.trim();
    });
    return JSON.stringify(jsonInput);
  }

  /**
   * Execute datatables search request for all $inputFields
   *
   * @private
   * @return {undefined}
   */
  function _doMultiInputSearch() {
    const searchInput = null;
    // We don't need to add search input, as datatables will add the data to the request, because we set the
    // ajax.data option to a callback that creates that data
    _datatablesSearch(searchInput);
  }

  /**
   * Execute datatables search request for single $objPrimaryInputField
   *
   * @private
   * @param {jQuery} $primaryInputField
   * @return {undefined}
   */
  function _doPrimaryInputSearch($primaryInputField) {
    _datatablesSearch($primaryInputField.val().trim());
  }

  /**
   * Call callback on value change of input element type text
   *
   * Uses defaultSearch
   *
   * @private
   * @param {HTMLElement} inputElement
   * @param {function} callback
   * @returns {undefined}
   */
  function _onInputTextChange(inputElement, callback) {
    let previousInput = inputElement.value.trim();
    $(inputElement).on('input', function () {
      const input = inputElement.value.trim();
      if (input !== previousInput) {
        previousInput = input;
        callback();
      }
    });
  }

  /**
   * Call callback on change of datetimepicker element value
   *
   * @private
   * @param {HTMLElement} inputElement
   * @param {function} callback
   * @returns {undefined}
   */
  function _onDatetimePickerChange(inputElement, callback) {
    let previousInput = inputElement.value;
    $(inputElement).on('change', function () {
      // Do a small timeout to give datetimepicker a chance to finish all its event triggers, so we can read the
      // value of the inputElement
      setTimeout(function () {
        const input = inputElement.value;
        if (input !== previousInput) {
          previousInput = input;
          callback();
        }
      }, 1);
    });
  }

  /**
   * Make datatables do a search request
   *
   * Uses previousSearch
   *
   * @private
   * @param {string} searchInput
   * @return {undefined}
   */
  function _datatablesSearch(searchInput) {
    if (window.medimo !== undefined && window.medimo.KeyboardNavigation !== undefined && window.medimo.KeyboardNavigation.enabled) {
      // Disable keyboard navigation while doing a search
      // (We could also do this by using addajaxloader(), but at the moment we use datatables' own spinner.)
      window.medimo.KeyboardNavigation.enabled = false;
      $(document).on('draw.dt', function () {
        // Enable keyboard navigation again on draw
        window.medimo.KeyboardNavigation.enabled = true;
      });
    }
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(function () {
      $oTable.fnFilter(searchInput);
    }, 300);
  }
};

window.initClientTable = function (objList, objFilterField, phpDatatablesOptions, phpFrontendConfig = {}) {
  const $title = $('title');
  $title.text($title.text().trim().replace(/\s{2,}/, ' '));

  if (window.medimo.Breadcrumb) {
    phpDatatablesOptions.options.oSearch = {"sSearch": window.medimo.Breadcrumb.getCurrentSearchValue()};
    phpDatatablesOptions.options.iDisplayStart = window.medimo.Breadcrumb.getCurrentDisplayStartValue();
  }

  $(objList).data('clientTableOptions', phpDatatablesOptions);
  $(objList).data('frontendConfig', phpFrontendConfig);
  $(objList).clientTable();
};

// Show the hidden datatablesWrapper when datatables is initialized (so it does not flash around)
// and then calculate the columns width
$(document).on('init.dt', function (e, settings) {
  const api = new $.fn.dataTable.Api(settings);
  const node = api.table().node();
  // CSS display is set to none for datatableswrapper, here we revert that to show the datatable
  $(node).closest('.datatableswrapper').show();
  api.columns.adjust();

  // initial focus search field backend reports
  if (window.medimo.Breadcrumb !== undefined) {
    const search = window.medimo.Breadcrumb;
    if (search._searchField != '') {
      search._searchField.focus();
    }
  }
});

function setUpNavBackOnBackspaceIn($field) {
  let previousValue = $field.val();
  let allowNavBackOnBackspace = true;
  let preventNavBackOnBackspaceTimeout = null;

  $field.on('keyup', function (e) {
    if (e.which === 8) { // Backspace
      // Navigate back on backspace in empty search field
      if (previousValue.length === 0 && allowNavBackOnBackspace) {
        history.go(-1);
        e.preventDefault();
        return false;
      } else if (previousValue.length > 0) {
        // Allow backspace back nav only when input field is empty and has been empty for timeout duration.
        allowNavBackOnBackspace = false;
        clearTimeout(preventNavBackOnBackspaceTimeout);
        preventNavBackOnBackspaceTimeout = setTimeout(function () {
          allowNavBackOnBackspace = true; // Allows nav after timeout.
        }, 1000);
      }
    }

    // Always store the current value
    previousValue = $field.val();
  });
}
