require('/resources/assets/js/src/medimo/less/medimo.less');

window.Notifier = require('/resources/assets/js/modules/Notifier.js');
window.ErrorReporter = require('/resources/assets/js/modules/ErrorReporter.js');
window.ConnectionManager = require('/resources/assets/js/modules/ConnectionManager.js');
window.Ajax = require('/resources/assets/js/modules/Ajax.js');
window.Switch = require('/resources/assets/js/modules/Switch.js');
window.MedimoLocalStorage = require('/resources/assets/js/modules/MedimoLocalStorage.js');
window.MedimoSessionStorage = require('/resources/assets/js/modules/MedimoSessionStorage.js');
window.Breadcrumbs = require('/resources/assets/js/modules/Breadcrumbs.js');
window.FileSaver = require('/resources/assets/js/modules/FileSaver.js');
window.WebSocketConnector = require('/resources/assets/js/modules/WebSocketConnector.js');
require('/resources/assets/js/src/medimo/modal-actions.js');
require('/resources/assets/js/src/medimo/initiatedatatable.js');
require('/resources/assets/js/src/medimo/formajaxprocessor.js');
require('/resources/assets/js/src/medimo/medicationguard-and-sidebar.js');
require('/resources/assets/js/src/medimo/keyboardnavigation.js');
require('/resources/assets/js/src/medimo/select2-multiwordmatch.js');
require('/resources/assets/js/scripts/onFocusNumber.js');
require('/resources/assets/js/src/medimo/_medimo.js');
require('/resources/assets/js/src/medimo/initBreadcrumb.js');

