'use strict';

if (typeof window === 'object') {
  window.Ajax = Ajax;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = Ajax;
}

/**
 * Class Ajax
 *
 * Send ajax requests. Uses $.ajax().
 * All $.ajax() calls in vendor code will be routed through our own method.
 * This gives us a central point through which all ajax request in our app are channeled.
 *
 * @class
 * @public
 * @constructor
 * @params {ConnectionManager} connectionManager
 * @returns {undefined}
 */
function Ajax(connectionManager) {
  /**
   * @type {string[]} Array of CSRF methods
   */
  this.CSRF_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];

  /**
   * @type {ConnectionManager}
   */
  this._connectionManager = connectionManager;

  // Copy $.ajax() so we can replace $.ajax() with our own method (bind() copies the method)
  // This way vendor code that uses $.ajax() will use our ajax method, and that spares us creating
  // lots of difficult extensions.
  $.extend({medimoAjax: $.ajax.bind({})});

  // Set our own method to $.ajax()
  $.ajax = this.sendFromJquery.bind(this);
}

/**
 * Send an ajax GET request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.get = function (url, options) {
  options = this._setMethod('GET', options);
  return this.send(url, options);
};

/**
 * Send an ajax DELETE request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.delete = function (url, options) {
  options = this._setMethod('DELETE', options);
  return this.send(url, options);
};

/**
 * Send an ajax HEAD request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.head = function (url, options) {
  options = this._setMethod('HEAD', options);
  return this.send(url, options);
};

/**
 * Send an ajax OPTIONS request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.options = function (url, options) {
  options = this._setMethod('OPTIONS', options);
  return this.send(url, options);
};

/**
 * Send an ajax POST request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.post = function (url, options) {
  options = this._setMethod('POST', options);
  return this.send(url, options);
};

/**
 * Send an ajax PUT request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.put = function (url, options) {
  options = this._setMethod('PUT', options);
  return this.send(url, options);
};

/**
 * Send an ajax PATCH request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.patch = function (url, options) {
  options = this._setMethod('PATCH', options);
  return this.send(url, options);
};

/**
 * Send an ajax request
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.send = function (url, options) {
  const self = this;
  options = this._normalizeMethod(options);
  options = this._addCsrfToken(url, options);
  const jqXhr = $.medimoAjax(url, options)
    .fail(function (jqXhr) {
      self._connectionManager.ajaxRequestFailed(url, options, jqXhr);
    });
  return jqXhr;
};

/**
 * Send an ajax request, coming from jQuery's $.ajax()
 *
 * @public
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} jQuery xhr object
 */
Ajax.prototype.sendFromJquery = function (url, options) {
  if (typeof url === 'object') {
    options = url;
    url = options.url;
  }
  return this.send(url, options);
};

/**
 * Execute a callback whenever an ajax request is complete
 *
 * Uses jQuery ajaxComplete()
 *
 * @param {function} callback
 * @returns {undefined}
 */
Ajax.prototype.onComplete = function (callback) {
  $(document).on('ajaxComplete', callback);
};

/**
 * Add the CSRF token to the request headers
 *
 * @private
 * @param {string} url
 * @param {Object} options $.ajax() options
 * @returns {Object} $.ajax() options with CSRF token added
 */
Ajax.prototype._addCsrfToken = function (url, options) {
  // Add CSRF token (given in the X-CSRF-TOKEN meta tag on the page) to all
  // ajax requests that use methods POST, PUT, PATCH or DELETE
  if (this._shouldAddCsrfToken(url, options.method)) {
    // Add CSRF token to header
    options = $.extend(options, { headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } });
  }
  return options;
};

/**
 * Make sure options.method is set.
 *
 * Uses options.type if present and options.method is not set.
 *
 * @private
 * @param {Object} options $.ajax() options
 * @returns {Object} $.ajax() options with options.method set
 */
Ajax.prototype._normalizeMethod = function (options) {
  if (typeof options.method === 'string' && options.method !== '') {
    return options; // options.method is set, so just return options.
  }

  // options.method is not set.
  if (typeof options.type === 'string' && options.method !== '') {
    // options.type is set, so use that.
    options.method = options.type; // jQuery prior to 1.9.0
  } else {
    // Fallback to GET.
    options.method = 'GET';
  }

  return options;
};

/**
 * Set the request method to the options object
 *
 * @private
 * @param {string} method
 * @param {Object} options $.ajax() options
 * @returns {Object} $.ajax() options with request method added
 */
Ajax.prototype._setMethod = function (method, options) {
  if (options === undefined) {
    options = {};
  }
  return $.extend(options, { method: method });
};

/**
 * Check if we should add the CSRF token to the request headers.
 *
 * @private
 * @param {string} url
 * @param {string} method
 * @returns {boolean}
 */
Ajax.prototype._shouldAddCsrfToken = function (url, method) {
  // Don't add the CSRF token when this is a CORS request.
  if (this._connectionManager.isCors(url)) {
    return false;
  }

  // Check if the method string is present in the array this.CSRF_METHODS
  // If so, we should add the CSRF token
  return this.CSRF_METHODS.includes(method.toUpperCase());
};
